export function withGermanHyphenation(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                style={{ ...props.style, hyphens: "auto" }}
                lang="de"
            />
        )
    }
}

export function withEnglishHyphenation(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                style={{ ...props.style, hyphens: "auto" }}
                lang="en"
            />
        )
    }
}
